import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { getAgentProfileId } from "./api/agentProfileId";
import { IoMdRefresh } from "react-icons/io";
import transferTask from "./api/transferTask";
import "../../loader.css";
const TransferModal = ({
  isOpen,
  onClose,
  onTransfer,
  userId,
  buddyList,
  accessToken,
  destinationId,
  interactionId,
  setShowWrapUp,
  showWrapUp,

  ani,
  intId,
}) => {
  const [agents, setAgents] = useState([]);
  const [queues, setQueues] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState("");
  const [selectedQueue, setSelectedQueue] = useState("");
  const [selectionType, setSelectionType] = useState("agent");
  const [agentProfileId, setAgentProfileId] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [buddies, setBuddies] = useState(null);
  const [status, setStatus] = useState("");
  // Fetch active agents
  const fetchAgents = async () => {
    try {
      setLoading(true);
      const agentProfileId = await getAgentProfileId(userId, accessToken);

      console.log("Agent profile ID:", agentProfileId);
      setAgentProfileId(agentProfileId);
      if (agentProfileId) {
        const body = {
          agentProfileId: agentProfileId,
          mediaType: "social",
          state: "Available",
        };
        axios
          .post("https://api.wxcc-us1.cisco.com/v1/agents/buddyList", body, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log("BUDDY ", response);
            setBuddies(buddyList);
            setAgents(buddyList);
            setLoading(false);
          })
          .catch((error) => {
            setError("Error fetching buddy list");
            setLoading(false);
          });
      }
    } catch (error) {
      setError("Error fetching user profile");
      setLoading(false);
      console.error("Error fetching agents:", error);
    }
  };

  const handleChange = (e) => {
    console.log("onChange triggered");
    setSelectedAgent(e.target.value);
    console.log("SELECTED VALUE", e.target.value);
  };

  // Fetch queues (You will need to implement this API on the backend)
  const fetchQueues = async () => {
    try {
      const response = await axios.post("/api/get-queues");
      setQueues(response.data.queues);
    } catch (error) {
      console.error("Error fetching queues:", error);
    }
  };

  // Fetch data based on the selected type (agent or queue)
  useEffect(() => {
    if (isOpen) {
      if (selectionType === "agent") {
        fetchAgents();
      } else {
        fetchQueues();
      }
    }
  }, [isOpen, selectionType]);

  const handleTransfer = async () => {
    try {
      const taskId = ani ? intId : interactionId;
      const result = await transferTask(accessToken, taskId, selectedAgent);
      setStatus("Transfer successful!");
      console.log(result);
      setShowWrapUp(true);
      onClose();
    } catch (error) {
      setStatus("Transfer failed!");
      console.error(error);
    }
  };
  useEffect(() => {
    console.log(
      "SELECT TRANSFER AGENT ID",
      selectedAgent,
      ani,
      intId,
      interactionId,
      "SHOW WRAPUP",
      showWrapUp
    );
  }, [selectedAgent, showWrapUp]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
      <motion.div
        className="bg-white rounded-lg shadow-lg p-6 w-96"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
      >
        <h2 className="text-xl font-semibold text-gray-800 mb-4">
          Transfer Conversation
        </h2>

        {/* Radio Buttons for selecting Agent or Queue */}
        <div className="mb-4">
          <div className="flex gap-4">
            <label className="flex items-center">
              <input
                type="radio"
                name="selectionType"
                value="agent"
                checked={selectionType === "agent"}
                onChange={() => setSelectionType("agent")}
                className="mr-2"
              />
              Agent
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                name="selectionType"
                value="queue"
                checked={selectionType === "queue"}
                onChange={() => setSelectionType("queue")}
                className="mr-2"
              />
              Queue
            </label>
          </div>
        </div>

        {/* Select Agent or Queue based on radio button */}
        <div className="mb-4">
          {selectionType === "agent" ? (
            <>
              <label
                className="block text-sm text-gray-600 mb-2"
                htmlFor="agent-select"
              >
                Select Active Agent:
              </label>
              <div className="flex gap-2 items-center">
                <select
                  id="agent-select"
                  value={selectedAgent}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-lg"
                >
                  {agents.length === 0 && (
                    <option value="" disabled>
                      No available agent
                    </option>
                  )}
                  <option value="" disabled>
                    -- select agent --
                  </option>
                  {agents.map((agent) => (
                    <option key={agent?.agentId} value={agent?.agentId}>
                      {agent?.agentName}
                    </option>
                  ))}
                </select>
                <button
                  onClick={fetchAgents}
                  className="bg-gray-200 text-gray-600 px-3 py-2 rounded-full hover:bg-gray-300 transition"
                >
                  {loading ? (
                    <span className="transferLoader"></span>
                  ) : (
                    <IoMdRefresh />
                  )}
                </button>
              </div>
            </>
          ) : (
            <>
              <label
                className="block text-sm text-gray-600 mb-2"
                htmlFor="queue-select"
              >
                Select Queue:
              </label>
              <div className="flex gap-2 items-center">
                <select
                  id="queue-select"
                  value={selectedQueue}
                  onChange={(e) => setSelectedQueue(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-lg"
                >
                  <option value="">--Select Queue--</option>
                  {queues.map((queue) => (
                    <option key={queue.id} value={queue.id}>
                      {queue.name}
                    </option>
                  ))}
                </select>
                <button
                  onClick={fetchQueues}
                  className="bg-gray-200 text-gray-600 px-3 py-2 rounded-full hover:bg-gray-300 transition"
                >
                  <IoMdRefresh />
                </button>
              </div>
            </>
          )}
        </div>

        {/* Buttons */}
        <div className="flex justify-end gap-4">
          <button
            onClick={onClose}
            className="bg-red-500 text-white px-4 py-2 rounded-full hover:bg-red-600 transition"
          >
            Cancel
          </button>
          <button
            onClick={handleTransfer}
            className="bg-gray-100 text-gray-700 px-4 py-2 rounded-full hover:bg-gray-200 transition"
          >
            Transfer
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default TransferModal;
