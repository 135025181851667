import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./chatApp.css";
import "./loader.css";

const InitialPage = ({ socket, sendMessageToParent, accessToken }) => {
  const [loading, setLoading] = useState(false);
  // const [accessToken, setAccessToken] = useState(null);
  const navigate = useNavigate();

  // Function to fetch IMI token and agent info periodically
  const fetchImiToken = async () => {
    try {
      // Step 1: Make the API request to get user data
      const { data } = await axios.get(
        "https://wcapi-us-site-1.imiengage.io/api/oauth/wxccauthorizenew/",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "content-type": "application/json",
            grant_type: "authorization_code",
          },
        }
      );
      console.log("TOKEN DATA", data);
      const imitoken = data?.result?.accessToken;
      const agentId = data?.result?.agentId;
      const agentName = data?.result?.agentName;
      const agentEmail = data?.result?.agentEmail;

      if (imitoken) {
        localStorage.setItem("accessToken", imitoken);
        localStorage.setItem("agentName", agentName);
        localStorage.setItem("agentId", agentId);
        localStorage.setItem("agentEmail", agentEmail);
        console.log("imi tokent is stored in localstorage");
      } else {
        console.error("Access token is missing from the response.");
        alert("Failed to retrieve access token.");
        return;
      }

      // Step 3: Check if accessToken is present in localStorage
      const storedImiToken = localStorage.getItem("accessToken");

      if (storedImiToken) {
        // Step 4: Navigate to the agent route if the access token is present
        console.log(
          "Access token found in localStorage, navigating to /agent..."
        );
        navigate("/agent");
      } else {
        console.error("Access token is not present in localStorage.");
        alert("No access token found. Cannot proceed.");
      }
    } catch (error) {
      console.error("Error during API call:", error);
      alert("Failed to go live. Please try again.");
    } finally {
      setLoading(false);
    }

    // Schedule the task to run every 29 minutes
    setInterval(fetchImiToken, 29 * 60 * 1000);
  };

  // Main function to handle Go Live logic
  // const handleGoLive = async () => {
  //   setLoading(true);
  //   try {
  //     const { data } = await axios.get("http://localhost:5500/api/getUserData");
  //     console.log(data);
  //     const { accessToken, agentId, loginType } = data.data;
  //     sendMessageToParent(data.data);
  //     // Store the access token in state
  //     // setAccessToken(accessToken);

  //     const intervalId = setInterval(fetchImiTokenAndAgentInfo, 29 * 60 * 1000); // 29 minutes
  //     // Proceed based on the login type
  //     if (loginType === "agent" || loginType === "supervisor") {
  //       // Emit WebSocket event based on role
  //       socket.emit("goLive", { uuid: agentId, role: loginType });

  //       // Navigate to the appropriate page
  //       if (loginType === "agent") {
  //         navigate("/agent");
  //       } else if (loginType === "supervisor") {
  //         navigate("/supervisor");
  //       }

  //       // Start the periodic fetching of IMI token and agent info every 29 minutes

  //       // Clear interval when component unmounts
  //       return () => clearInterval(intervalId);
  //     } else {
  //       alert("Invalid login type received from the server.");
  //     }
  //   } catch (error) {
  //     console.error("Error during API call:", error);
  //     alert("Failed to go live. Please try again.");
  //   } finally {
  //     setLoading(false);
  //   }
  //   // navigate("/agent");
  // };
  // const handleGoLive = async () => {
  //   setLoading(true);
  //   try {
  //     // Fetch user data
  //     const { data } = await axios.get("http://localhost:5500/api/getUserData");
  //     console.log(data);
  //     const { accessToken, agentId, loginType } = data.data;
  //     sendMessageToParent(data.data);

  //     // Log the data to ensure correct response
  //     console.log(
  //       "AccessToken:",
  //       accessToken,
  //       "AgentID:",
  //       agentId,
  //       "LoginType:",
  //       loginType
  //     );

  //     // Emit WebSocket event based on role
  //     socket.emit("goLive", { uuid: agentId, role: loginType });

  //     // Set up the periodic fetching of IMI token and agent info
  //     const intervalId = setInterval(fetchImiTokenAndAgentInfo, 29 * 60 * 1000); // 29 minutes

  //     // Proceed based on the login type
  //     if (loginType === "agent" || loginType === "supervisor") {
  //       // Wait for fetchImiTokenAndAgentInfo to complete and return status 200
  //       const fetchImiResponse = await fetchImiTokenAndAgentInfo();

  //       if (fetchImiResponse && fetchImiResponse.status === 200) {
  //         // Navigate to the agent page only if status is 200
  //         if (loginType === "agent") {
  //           navigate("/agent");
  //         } else if (loginType === "supervisor") {
  //           navigate("/supervisor");
  //         }
  //       } else {
  //         alert("Failed to fetch IMI token. Please try again.");
  //       }

  //       // Clear interval when component unmounts
  //       return () => clearInterval(intervalId);
  //     } else {
  //       alert("Invalid login type received from the server.");
  //     }
  //   } catch (error) {
  //     console.error("Error during API call:", error);
  //     alert("Failed to go live. Please try again.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // **********************************************
  const handleGoLive = async () => {
    setLoading(true);
    console.log("access token for cce is ", accessToken);

    fetchImiToken();
  };

  return (
    <div className="h-screen w-full flex items-center justify-center bg-gray-800">
      {loading ? (
        <svg
          class="ip"
          viewBox="0 0 256 128"
          width="128px"
          height="64px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient id="grad1" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stop-color="#5ebd3e" />
              <stop offset="33%" stop-color="#ffb900" />
              <stop offset="67%" stop-color="#f78200" />
              <stop offset="100%" stop-color="#e23838" />
            </linearGradient>
            <linearGradient id="grad2" x1="1" y1="0" x2="0" y2="0">
              <stop offset="0%" stop-color="#e23838" />
              <stop offset="33%" stop-color="#973999" />
              <stop offset="67%" stop-color="#009cdf" />
              <stop offset="100%" stop-color="#5ebd3e" />
            </linearGradient>
          </defs>
          <g fill="none" stroke-linecap="round" stroke-width="16">
            <g class="ip__track" stroke="#ddd">
              <path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56" />
              <path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64" />
            </g>
            <g stroke-dasharray="180 656">
              <path
                class="ip__worm1"
                stroke="url(#grad1)"
                stroke-dashoffset="0"
                d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"
              />
              <path
                class="ip__worm2"
                stroke="url(#grad2)"
                stroke-dashoffset="358"
                d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"
              />
            </g>
          </g>
        </svg>
      ) : (
        <button
          className="text-white font-bold text-2xl py-4 px-8 rounded-full bg-gradient-to-r from-green-400 via-green-500 to-green-600 shadow-lg hover:shadow-green-400/50 transition duration-300 ease-in-out transform hover:scale-105 animate-pulse"
          onClick={handleGoLive}
        >
          Go Live
        </button>
      )}
    </div>
  );
};

export default InitialPage;
