import axios from "axios";

const transferTask = async (accessToken, taskId, destinationId) => {
  const url = `https://api.wxcc-us1.cisco.com/v1/tasks/${taskId}/transfer`;

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };

  const data = {
    destinationType: "agent",
    to: destinationId,
  };

  try {
    const response = await axios.post(url, data, { headers });
    console.log("Task transferred successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Error transferring task:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export default transferTask;
