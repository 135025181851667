import axios from "axios";

export const getAgentProfileId = async (userId, accessToken) => {
  try {
    const response = await axios.get(
      `https://api.wxcc-us1.cisco.com/organization/69fc3aba-280a-4f8e-b449-2c198d78569b/user/by-ci-user-id/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response?.data?.agentProfileId;
  } catch (error) {
    console.error("Error fetching agent profile:", error);
    throw new Error(
      error.response?.data?.message || "Failed to fetch agent profile"
    );
  }
};
