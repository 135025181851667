import React, { useEffect, useState } from "react";
import { getAuxiliaryCodes } from "./api/auxiliaryCode";
import { getWrapUpCodes } from "./api/wrapUpCodes";
import { getAgentProfileId } from "./api/agentProfileId";

const WrapUpReasonSelector = ({
  accessToken,
  threadId,
  wrapUpThreadId,
  handleSelectChange,
  handleSubmit,
  showWrapUp,
  endLoading,
}) => {
  const [wrapUpReasons, setWrapupReasons] = useState([]);

  useEffect(() => {
    const fetchWrapupReasons = async () => {
      try {
        const auxiliaryCodes = await getAuxiliaryCodes(accessToken);
        const profileId = await getAgentProfileId(
          localStorage.getItem("agentId"),
          accessToken
        );
        const wrapUpCodes = await getWrapUpCodes(profileId, accessToken);
        const filteredCodes = auxiliaryCodes.filter((auxCode) =>
          wrapUpCodes.some((wrapCode) => wrapCode === auxCode.id)
        );
        console.log(`wrapUpReasons  ${JSON.stringify(auxiliaryCodes)}`);
        setWrapupReasons(filteredCodes);
      } catch (error) {
        console.error("Error fetching wrapup reasons:", error);
      }
    };

    fetchWrapupReasons();
  }, [accessToken]);

  if ((wrapUpThreadId !== threadId && showWrapUp === true) || endLoading) {
    return null; // Do not render if threadId does not match or loading is true
  }

  return (
    <div className="mt-4 bg-gray-100 p-4 rounded-lg">
      <label
        htmlFor="wrapUpSelect"
        className="block text-gray-600 text-xs font-light mb-1"
      >
        Wrap-Up Options
      </label>
      <select
        id="wrapUpSelect"
        onChange={handleSelectChange}
        className="block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
      >
        <option value="">Select an option</option>
        {wrapUpReasons &&
          wrapUpReasons
            .filter((value) => value?.workTypeCode === "WRAP_UP_CODE")
            .map((value) => (
              <option key={value?.id} value={value?.id}>
                {value?.name}
              </option>
            ))}
      </select>
      <button
        onClick={handleSubmit}
        className="mt-2 w-full py-2 bg-blue-500 text-white text-sm rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
      >
        Submit
      </button>
    </div>
  );
};

export default WrapUpReasonSelector;
