import axios from "axios";

export const getAuxiliaryCodes = async (accessToken) => {
  const url = `https://api.wxcc-us1.cisco.com/organization/69fc3aba-280a-4f8e-b449-2c198d78569b/auxiliary-code`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching wrapup reasons:", error);
    throw new Error(
      error.response?.data?.message || "Failed to fetch wrapup reasons"
    );
  }
};
