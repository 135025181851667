import axios from "axios";

export const getWrapUpCodes = async (profileId, accessToken) => {
  const url = `https://api.wxcc-us1.cisco.com/organization/69fc3aba-280a-4f8e-b449-2c198d78569b/agent-profile/${profileId}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    console.log("WRAP UP CODES", response);

    return response?.data?.wrapUpCodes;
  } catch (error) {
    console.error("Error fetching agent profile:", error);
    throw new Error(
      error.response?.data?.message || "Failed to fetch agent profile"
    );
  }
};
